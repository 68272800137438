import { log } from './logger.js';
const scriptSource = (() => {
    const file = 'vim-sdk.umd.js';
    const path = `vim-os-sdk/v2.x.x/${file}`;
    const appEnv = globalThis.$vim_environment?.APP_ENV ?? 'prod';
    switch (appEnv) {
        case 'local':
            return `${window.location.protocol + '//' + window.location.host}/${file}`;
        case 'dev':
        case 'staging': {
            const domain = window.location.origin.includes('getvim.com') ? 'getvim.com' : 'devim.io';
            return `https://connect.${appEnv}.${domain}/${path}`;
        }
        case 'demo':
            return `https://connect.demo.getvim.com/${path}`;
        case 'prod':
        default:
            return `https://connect.getvim.com/${path}`;
    }
})();
const createScriptElement = (url, attributes = {}) => {
    const newScript = document.createElement('script');
    newScript.src = url;
    Object.keys(attributes).forEach((key) => {
        newScript.setAttribute(key, attributes[key]);
    });
    return newScript;
};
const insertScriptElement = (url, attributes = {}) => new Promise((resolve, reject) => {
    const newScript = createScriptElement(url, attributes);
    newScript.addEventListener('error', (error) => {
        console.error('failed to load Vim SDK', error);
        log({
            message: 'failed to load sdk',
            scope: 'sdk-npm-setup',
            error,
            data: {
                sdkUrl: url,
                attributes,
            },
        });
        reject();
    });
    newScript.addEventListener('load', () => resolve());
    document.head.insertBefore(newScript, document.head.firstElementChild);
});
const loadSdk = async (options) => {
    await insertScriptElement(options?.sdkUrl || scriptSource);
    return await globalThis.vimSdk.initializeVimSDK(options);
};
export { loadSdk };
