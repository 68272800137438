const errorToObject = (error) => {
    const errorObject = {};
    for (const prop of Object.getOwnPropertyNames(error)) {
        errorObject[prop] = error[prop];
    }
    return errorObject;
};
const coralogixEnv = (() => {
    const appEnv = globalThis.$vim_environment?.APP_ENV;
    if (!appEnv) {
        return 'prod';
    }
    if (appEnv === 'local') {
        return 'dev';
    }
    return appEnv;
})();
const getCoraLogicsCapacitorUrlByEnv = (logLevel) => {
    const path = `${logLevel}.log`;
    return new URL(path, `https://client-logs${coralogixEnv === 'prod' ? '.' : `.${coralogixEnv}.`}getvim.com/`).href;
};
export const log = ({ message, data, error, scope }) => {
    const logLevel = error ? 'error' : 'other';
    const body = JSON.stringify({
        log: {
            level: logLevel,
            scope,
            message,
            metadata: {
                scope,
                data: {
                    error: error ? errorToObject(error) : undefined,
                    ...(data ?? {}),
                },
                clientTime: Date.now(),
                metadata: {},
            },
        },
    });
    return fetch(`${getCoraLogicsCapacitorUrlByEnv(logLevel)}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body,
    });
};
