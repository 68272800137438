import { VimOSContext } from '../vimOsContext';
import { logger } from '@getvim/vim-connect-logger';
import { useContext, useEffect } from 'react';

export const useEnrichLoggerMetadata = (): void => {
  const log = logger.scope('Clover Assist [useEnrichLoggerMetadata]');
  const vimOS = useContext(VimOSContext);
  const { organization, ehrType, user, sessionId } = vimOS.sessionContext;

  log.info('Init Enriching logger metadata', {
    noPHI: true,
    organization,
    ehrType,
    user,
    sessionId,
  });
  useEffect(() => {
    try {
      log.info('Setting logger metadata', {
        ehrUserName: user?.identifiers?.ehrUsername,
        noPHI: true,
        sessionId,
      });
      logger.setMetadata({
        deviceId: '',
        adapterName: ehrType,
        organizationId: organization?.identifiers?.id,
        organization_name: organization?.identifiers?.name,
        version: window?.$vim_environment?.version || '',
        windowId: '',
        ehrUserName: user?.identifiers?.ehrUsername,
      });
      logger.setUserMetadata({ userSessionId: sessionId });
    } catch (error) {
      log.warning('Error setting logger metadata', { error: error, noPHI: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, ehrType, user, sessionId]);
};
