import type { EHR } from 'vim-os-js-browser/types';
import { useContext, useEffect, useState } from 'react';
import { useGlobalState } from '../stores/GlobalStore';
import { GlobalStateActionType } from '../stores/globalStore.types';
import { Screens, FullOrganization } from '../types';
import { createLogger } from '../utils';
import {
  PubnubService,
  PubsubReceivedStatusEvent,
  PubsubReceivedMessageEvent,
} from '@getvim/vim-connect-pubsub';
import { logic } from '../logic';
import { CheckEligibilityResponse } from '../api/assist-bff/types';
import { patientInsuranceValidation } from '../utils/patientInsuranceValidation';
import { getConfig } from '../config';
import { generateLaunchId } from '../utils/generateLaunchId';
import { VimOSContext } from '../vimOsContext';

const logger = createLogger('useEhrStateHandlers v2');

let pubsubService;

/**
 * A custom hook to handle ehr state reactive logic
 */
const useEhrStateHandlers = () => {
  const { dispatch, state } = useGlobalState();

  const vimOS = useContext(VimOSContext);
  const { user, organization } = vimOS.sessionContext;

  const [isAppOpen, setIsAppOpen] = useState<boolean | undefined>(undefined);
  const [patient, setPatient] = useState<EHR.Patient | undefined>(undefined);
  const [encounter, setEncounter] = useState<EHR.Encounter | undefined>(undefined);
  const [fullOrganization, setFullOrganization] = useState<FullOrganization>();

  const [eligibility, setEligibility] = useState<CheckEligibilityResponse>();

  const [pubNubConfig, setPubNubConfig] = useState<any>();

  const { VIM_SHOULD_CHECK_INSURANCE, service: serviceName } = getConfig();

  useEffect(() => {
    if (vimOS.ehr) {
      vimOS.ehr.subscribe('patient', setPatient);
      vimOS.ehr.subscribe('encounter', setEncounter);

      return () => {
        vimOS.ehr.unsubscribe('patient', setPatient);
        vimOS.ehr.unsubscribe('encounter', setEncounter);
      };
    }
  }, [vimOS.ehr]);

  useEffect(() => {
    if (vimOS.hub) {
      vimOS.hub.appState.subscribe('appOpenStatus', (data) => {
        setIsAppOpen(data?.isAppOpen);
      });
      return () => {
        vimOS.hub.appState.unsubscribe('appOpenStatus', (data) => {
          setIsAppOpen(data?.isAppOpen);
        });
      };
    }
  }, [vimOS.hub]);

  useEffect(() => {
    if (isAppOpen) {
      onOpen()
        .then(() => {
          logger.info('App opened', { noPHI: true });
        })
        .catch((error) => {
          logger.error('Failed to open app', { error, noPHI: true });
        });
    } else {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppOpen]);

  // ----------------------------------------
  // Patient Handler
  // ----------------------------------------
  useEffect(() => {
    (async () => {
      if (patient) {
        const patientContext = {
          patientId: patient?.identifiers?.vimPatientId,
          insurance: patient?.insurance?.ehrInsurance,
        };
        logger.info('Patient in context', { noPHI: true, ...patientContext });
        if (!user?.identifiers || !fullOrganization || !fullOrganization?.id) {
          logger.info('Mandatory information was not received', { noPHI: true, ...patientContext });
          return;
        }
        logger.info('Before checking eligibility', {
          noPHI: true,
          VIM_SHOULD_CHECK_INSURANCE,
          ...patientContext,
        });
        if (!patientInsuranceValidation(VIM_SHOULD_CHECK_INSURANCE, patient)) {
          logger.info('Patient insurance is not eligible', {
            noPHI: true,
            VIM_SHOULD_CHECK_INSURANCE,
            ...patientContext,
          });
          return;
        }

        logger.info('Patient insurance is eligible', { noPHI: true, ...patientContext });

        const eligibility = await logic.eligibility({
          patient,
          organization: fullOrganization,
          ehrUsername: user.identifiers.ehrUsername,
        });

        logger.info('Got eligibility response', {
          eligibility: eligibility?.eligible,
          noPHI: true,
          ...patientContext,
        });

        if (!eligibility || !eligibility?.eligible) {
          logger.info('Patient is not eligible', { noPHI: true, ...patientContext });
          return;
        }

        setEligibility(eligibility);
        logger.info('Got eligibility', { noPHI: true, ...patientContext });
      } else {
        vimOS.hub.setActivationStatus('DISABLED');
        setEligibility(undefined);
      }
    })().catch((error) => {
      logger.error('Failed to check eligibility', { error });
      logger.error('Failed to check eligibility', { message: error.message, noPHI: true });
    });
  }, [fullOrganization, patient, user, vimOS.hub, VIM_SHOULD_CHECK_INSURANCE]);

  // New PubSub subscription implementation
  useEffect(() => {
    (async () => {
      if (!fullOrganization || !pubNubConfig) {
        return;
      }
      if (!encounter || !eligibility?.eligible) {
        logger.info('Start deleting PubSub service', { noPHI: true });
        if (pubsubService) {
          await pubsubService.pubnub.unsubscribeAll();
          pubsubService = undefined;
        }
        return;
      }
      logger.info('Start initiation PubSub service', {
        noPHI: true,
        encounterId: encounter.identifiers?.ehrEncounterId,
      });
      const { incomeChannel, token, config, agentId } = pubNubConfig;
      pubsubService = new PubnubService({
        uuid: agentId,
        authKey: token,
        ...config,
      });
      pubsubService.addListener({
        status: (event: PubsubReceivedStatusEvent) => {
          logger.info('Received PubSub status', { event });
        },
        message: async (event: PubsubReceivedMessageEvent) => {
          logger.info('Received new PubSub message', {
            noPHI: true,
            encounterId: encounter.identifiers?.ehrEncounterId,
          });
          const { message } = event;
          const decryptMessage = pubsubService?.decrypt({
            cipherKey: fullOrganization.cipherKey!,
            secureText: `${message}`,
          });
          const { responseChannel, actionData } = decryptMessage;
          const encrypted = pubsubService?.encrypt({
            cipherKey: fullOrganization.cipherKey!,
            clearText: JSON.stringify({ actionId: actionData?.actionId }),
          });
          if (actionData?.data?.data?.service !== serviceName) {
            return;
          }
          logger.info('Got encrypted PubSub message', {
            noPHI: true,
            encounterId: encounter.identifiers?.ehrEncounterId,
            actionId: actionData?.actionId,
          });
          await pubsubService?.publish({
            channel: responseChannel,
            message: { encrypted: encrypted!, orgSlug: fullOrganization.slug },
          });
          try {
            const writebackData = actionData.data;
            logger.info('Before start writeback', {
              actionDataEncounterId: writebackData.data?.encounterId,
              encounterId: encounter.identifiers?.ehrEncounterId,
              noPHI: true,
            });

            if (writebackData?.data?.encounterId != encounter?.identifiers?.ehrEncounterId) {
              logger.warning('EncounterId is not match', {
                actionData: writebackData.data?.encounterId,
                encounterId: encounter?.identifiers?.ehrEncounterId,
                noPHI: true,
              });
              return;
            }
            if (!writebackData.data?.diagnoses) {
              return;
            }
            const canUpdateAssessment = await vimOS.ehr.resourceUpdater.canUpdateEncounter({
              assessment: { diagnosisCodes: writebackData.data?.diagnoses },
            });
            if (!canUpdateAssessment) {
              throw new Error('Cannot update assessment');
            }
            await vimOS.ehr.resourceUpdater.updateEncounter({
              assessment: { diagnosisCodes: writebackData.data?.diagnoses },
            });

            logger.info('Assesment successfully updated', {
              actionId: actionData?.actionId,
              encounterId: encounter?.identifiers?.ehrEncounterId,
              noPHI: true,
            });
          } catch (error) {
            logger.error('Failed to update encounter', {
              actionId: actionData?.actionId,
              encounterId: encounter?.identifiers?.ehrEncounterId,
              noPHI: true,
            });
            logger.error('Failed to update encounter', { actionId: actionData?.actionId, error });
          }
        },
      });
      logger.info('Subscribing to PubSub', {
        incomeChannel,
        noPHI: true,
        encounterId: encounter?.identifiers?.ehrEncounterId,
      });
      pubsubService.subscribe({ channels: [incomeChannel] });
    })();

    return () => {
      pubsubService?.pubnub.unsubscribeAll();
      pubsubService = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encounter, eligibility, pubNubConfig, fullOrganization]);

  useEffect(() => {
    (async () => {
      if (user?.identifiers && !pubsubService && organization?.identifiers) {
        logger.info('Start Initializing pubsubService service', {
          noPHI: true,
          organizationName: organization?.identifiers.name,
          organizationId: organization?.identifiers.id,
        });

        const {
          token,
          config,
          organization: fullOrganization,
          incomeChannel,
          agentId,
        } = await logic.pubSubServiceInit({
          userId: user.identifiers.vimUserID,
          organizationId: organization?.identifiers?.id,
        });
        setPubNubConfig({ incomeChannel, token, config, agentId });

        setFullOrganization({
          ...organization.identifiers,
          ...fullOrganization!,
        });
        logger.info('PubsubService service activated', {
          noPHI: true,
          organizationName: organization?.identifiers.name,
          organizationId: organization?.identifiers.id,
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, organization]);

  // ----------------------------------------
  // Encounter Handler
  // ----------------------------------------
  useEffect(() => {
    (async () => {
      if (encounter) {
        logger.info('Encounter in context', {
          noPHI: true,
          encounterId: encounter.identifiers?.ehrEncounterId,
        });

        if (!eligibility) {
          logger.info('There is no eligibility info', {
            noPHI: true,
            encounterId: encounter.identifiers?.ehrEncounterId,
          });
          vimOS.hub.setActivationStatus('DISABLED');
          return;
        }

        vimOS.hub.setActivationStatus('ENABLED');
        vimOS.hub.autoPopup();
        logger.info('App acivated', {
          noPHI: true,
          encounterId: encounter.identifiers?.ehrEncounterId,
        });
      } else {
        vimOS.hub.setActivationStatus('DISABLED');
      }
    })();
  }, [encounter, eligibility, vimOS.hub.autoPopup, vimOS.hub]);

  const onOpen = async () => {
    dispatch({
      type: GlobalStateActionType.SET_SCREEN,
      payload: {
        screen: Screens.Loading,
      },
    });

    if (!user?.identifiers || !fullOrganization?.tin || !fullOrganization?.id || !pubNubConfig) {
      logger.warning('Mandatory information was not received', {
        noPHI: true,
      });
      vimOS.hub.setActivationStatus('DISABLED');
      return;
    }
    const { incomeChannel } = pubNubConfig;
    const launchId = generateLaunchId({
      incomeChannel,
      encounterId: `${encounter?.identifiers?.ehrEncounterId}`,
    });
    logger.info('After setup launchId', {
      encounterId: encounter?.identifiers?.ehrEncounterId,
      launchId,
      noPHI: true,
    });

    const launchUrl = encounter
      ? await logic.launchUrl({
          eligibility: eligibility!,
          launchId,
          organization: { tin: fullOrganization.tin, id: fullOrganization.id },
          encounter,
          ehrUsername: user.identifiers.ehrUsername,
          vimUserID: user.identifiers.vimUserID,
        })
      : null;

    if (!launchUrl) {
      logger.error('Failed to get launchUrl', {
        noPHI: true,
        encounterId: encounter?.identifiers?.ehrEncounterId,
      });
      return dispatch({
        type: GlobalStateActionType.SET_SCREEN,
        payload: {
          url: null,
          screen: Screens.CloverAssist,
          launchId,
        },
      });
    }

    logger.info('Got launchUrl', {
      noPHI: true,
      encounterId: encounter?.identifiers?.ehrEncounterId,
      launchId,
    });

    dispatch({
      type: GlobalStateActionType.SET_SCREEN,
      payload: {
        url: launchUrl,
        screen: Screens.CloverAssist,
        launchId,
      },
    });
  };

  const onClose = () => {
    dispatch({ type: GlobalStateActionType.SET_SCREEN, payload: { screen: Screens.None } });
  };

  useEffect(() => {
    switch (state.lastAction?.type) {
      case GlobalStateActionType.ON_OPEN: {
        onOpen();
        break;
      }
      case GlobalStateActionType.ON_CLOSE: {
        onClose();
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.lastAction]);
};

export default useEhrStateHandlers;
