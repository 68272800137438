import type { EHR } from 'vim-os-js-browser/types';

function checkInsurance(insurance: string) {
  return insurance.toLocaleLowerCase().includes('clover');
}

export function patientInsuranceValidation(
  VIM_SHOULD_CHECK_INSURANCE: boolean,
  patient: EHR.Patient,
) {
  const { ehrInsurance } = patient?.insurance || {};
  if (!ehrInsurance) {
    return false;
  }

  return VIM_SHOULD_CHECK_INSURANCE ? checkInsurance(ehrInsurance) : !checkInsurance(ehrInsurance);
}
