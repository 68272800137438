import { VimSDKProvider } from '@getvim/internal-vim-os-sdk/react';
import '@getvim/atomic-ui/assets/styles/main.less';
import { ThemeVariablesWrapper, themes } from '@getvim/components-hooks-use-theme';
import { TelemetryProvider } from '@getvim/opentelemetry-sdk/react';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { GlobalStateProvider } from './stores/GlobalStore';
import { FeatureFlagProvider } from '@getvim/feature-flags-react';
import { featureFlagsClient } from './logic/featureFlags';
import App from './components/App';
import { RequiredStateWrapper } from './components/RequiredStateWrapper';
import AppV2 from './components-v2/App';
import { RequiredStateWrapper as RequiredStateWrapperV2 } from './components-v2/RequiredStateWrapper';
import { VimSdkWrapper } from './components-v2/VimSdkWrapper';
import './index.less';
import { AppEnv } from './types';
import { getConfig } from './config';
import { isBoolean } from 'lodash-es';

/* eslint-disable @typescript-eslint/no-explicit-any */
const appEnv: AppEnv = (window as any).$vim_environment?.APP_ENV;

const AppSdkWrapper = () => {
  const { service } = getConfig();

  const [shouldUseExternalVimSDK, setShouldUseExternalVimSDK] = useState<boolean | null>(null);

  const fetchShouldUseExternalVimSDKFF = useCallback(async () => {
    const shouldUseExternalVimSDKFF = await featureFlagsClient.getFlag({
      flagName: 'shouldUseExternalVimSDK',
      defaultValue: false,
    });
    setShouldUseExternalVimSDK(shouldUseExternalVimSDKFF);
  }, []);

  useEffect(() => {
    fetchShouldUseExternalVimSDKFF();
  }, [fetchShouldUseExternalVimSDKFF]);

  const appContent = (
    <VimSDKProvider>
      <ThemeVariablesWrapper theme={themes.vim}>
        <FeatureFlagProvider featureFlagsClient={featureFlagsClient}>
          <GlobalStateProvider>
            <RequiredStateWrapper>
              <App />
            </RequiredStateWrapper>
          </GlobalStateProvider>
        </FeatureFlagProvider>
      </ThemeVariablesWrapper>
    </VimSDKProvider>
  );

  const appContentV2 = (
    <ThemeVariablesWrapper theme={themes.vim}>
      <FeatureFlagProvider featureFlagsClient={featureFlagsClient}>
        <GlobalStateProvider>
          <VimSdkWrapper>
            <RequiredStateWrapperV2>
              <AppV2 />
            </RequiredStateWrapperV2>
          </VimSdkWrapper>
        </GlobalStateProvider>
      </FeatureFlagProvider>
    </ThemeVariablesWrapper>
  );

  return (
    isBoolean(shouldUseExternalVimSDK) && (
      <TelemetryProvider
        options={{
          serviceName: service,
          env: appEnv,
          enableLogs: appEnv === AppEnv.DEV,
          enableMetrics: appEnv === AppEnv.DEV,
          enableTraces: appEnv === AppEnv.DEV,
        }}
      >
        {shouldUseExternalVimSDK ? appContentV2 : appContent}
      </TelemetryProvider>
    )
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<AppSdkWrapper />);
