import type { EHR } from 'vim-os-js-browser/types';
import { internalApi } from '../../api';
import { CheckEligibilityResponse, GetLaunchUrlInput } from '../../api/assist-bff/types';

const launchUrl = async (input: {
  eligibility: CheckEligibilityResponse;
  organization: {
    tin: string;
    id: number;
  };
  launchId: string;
  encounter: EHR.Encounter & {
    token?: string;
    loading?: boolean;
  };
  ehrUsername: string;
  vimUserID: string;
}): Promise<string | undefined> => {
  if (input) {
    const formattedInput = formatRequest(input);
    if (formattedInput) {
      return await internalApi.cloverAssistBffApi.getLaunchUrl(formattedInput);
    }
  }
};

const formatRequest = (input: {
  eligibility: CheckEligibilityResponse;
  organization: { tin: string; id: number };
  encounter: EHR.Encounter & {
    token?: string;
    loading?: boolean;
  };
  launchId: string;
  ehrUsername: string;
  vimUserID: string;
}): GetLaunchUrlInput => {
  const { eligibility, organization, encounter, ehrUsername, launchId } = input;

  return {
    patientToken: eligibility.patientToken,
    encounterDate: encounter?.basicInformation?.encounterDateOfService,
    launchId,
    tin: organization.tin,
    provider: {
      firstName: encounter.provider?.demographics.firstName,
      lastName: encounter.provider?.demographics.lastName,
      npi: encounter.provider?.npi,
    },
    ehrUsername: ehrUsername,
    orgId: `${organization.id}`,
  };
};

export { launchUrl };
