import React, { useEffect, useState } from 'react';
import { loadSdk } from 'vim-os-js-browser';
import { SDK } from 'vim-os-js-browser/types';
import { VimOSContext } from '../vimOsContext';

export const VimSdkWrapper: React.FC = ({ children }) => {
  const [vimOS, setVimOS] = useState<SDK | undefined>(undefined);

  useEffect(() => {
    (async () => {
      setVimOS(await loadSdk());
    })();
  }, []);

  if (!vimOS) {
    return <div>Loading VimSDK...</div>;
  }

  return <VimOSContext.Provider value={vimOS}>{children}</VimOSContext.Provider>;
};
