import { getConfig } from '../config';

interface GetLaunchUrlInput {
  incomeChannel: string;
  encounterId: string;
}

export function generateLaunchId({ incomeChannel, encounterId }: GetLaunchUrlInput): string {
  const { service } = getConfig();
  return btoa(`${incomeChannel}:${encounterId}:${service}`);
}
