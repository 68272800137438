import React, { useCallback, useContext, useState, useEffect } from 'react';
import { FlowContextWrapper } from '@getvim/flow-context-provider';
import { Loader } from '@getvim/atomic-ui';
import { internalApi } from '../api';
import { createLogger } from '../utils';
import { VimOSContext } from '../vimOsContext';

export const RequiredStateWrapper = ({ children }) => {
  const { sessionContext } = useContext(VimOSContext);
  const logger = createLogger('RequiredStateWrapper');

  const [mandatoryState, setMandatoryState] = useState<any>(null);

  useEffect(() => {
    const fetchAccessToken = async () => {
      const { idToken } = await sessionContext.getIdToken();
      if (idToken) {
        logger.info('App has received token');
        internalApi.cloverAssistBffApi.setAccessToken(idToken);
      }
      setMandatoryState({
        accessToken: Promise.resolve(idToken),
      });
    };
    fetchAccessToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionContext]); // do not add logger here, it will cause a circular dependency

  const onError = useCallback(() => {
    logger.error('Failed to fetch state in App RequiredStateWrapper', { mandatoryState });
  }, [logger, mandatoryState]);

  return mandatoryState ? (
    <FlowContextWrapper loader={<Loader type="dots" />} state={mandatoryState} onError={onError}>
      {children}
    </FlowContextWrapper>
  ) : (
    <div>Loading VimSDK...</div>
  );
};
